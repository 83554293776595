import { Injectable , inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { UtilitySerice } from './utility.service';



@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private apiUrl: string;

    
  _utilityService = inject(UtilitySerice)

    constructor(
        private http: HttpClient,
    ) {
        this.apiUrl = environment.url;
    }
   get<T>(url: string, httpParams?: any, loader: boolean = true) {
        if (httpParams) {
            for (let item in httpParams) {
                if (httpParams[item] === '' || httpParams[item] === undefined || httpParams[item] === null) {
                    delete httpParams[item];
                }
            }
        }
    
        const options: any = {
            params: httpParams,
            observe: 'body' as const // Ensures only the response body is returned
        };
    
        return this.http.get<T>(this.apiUrl + url, options);
    }

    post<T>(url : string, data? : string, query? : string, loader = true) {
       
        let trimData;
        if (data) {
            trimData = this._utilityService.trim(data);
        }

        return this.http.post<T>(this.apiUrl + url, trimData);
    }

    patch<T>(url : string, data? : string, loader = true){
        
        const trimData = this._utilityService.trim(data);
        return this.http.patch(this.apiUrl + url, trimData);
    }

    
    
  

}
